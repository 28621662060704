import { template as template_a246562bdd864a1cb7de3e5488a760dc } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_a246562bdd864a1cb7de3e5488a760dc(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
