import { template as template_d463f4bd8e0547fe9d52fac3c1f74736 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_d463f4bd8e0547fe9d52fac3c1f74736(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
