import { template as template_633b5be5e56f48f79d48b96cd07235da } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
const TagNotificationsTracking = template_633b5be5e56f48f79d48b96cd07235da(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{false}}
    @showFullTitle={{false}}
    @prefix="tagging.notifications"
    class="tag-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TagNotificationsTracking;
